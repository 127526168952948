@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Raleway", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.tooltip-container {
  max-width: 380px;
  background-color: #102140 !important;
  padding: 10px;
  color: white !important;
  font-size: 11px;
  border-radius: 10px;
  border: none !important;
}

.tooltip-arrow[data-placement*='top']::after {
  border-color: #102140 transparent transparent transparent !important;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-arrow[data-placement*='right']::after {
  border-color: transparent #102140 transparent transparent !important;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #e86c6d;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 12px;
  width: 12px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #e86c6d;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 12px;
  width: 12px;
  pointer-events: all;
  position: relative;
}
